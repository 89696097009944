<template>
  <b-overlay :show="showOverlay">
    <b-card class="data-edit-wrapper">
      <h2>Page Details</h2>

      <b-form class="mt-2">
        <b-row>
          <b-col class="" md="6">
            <b-form-group
                label="page title"
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <b-form-input
                    id="data-edit-seoTitle"
                    v-model="data.title"
                    :state="errors.length > 0 ? false:null"
                />
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col cols="10">
                <b-form-group
                    class=""
                    label="page address"
                    label-for="data-edit-seourl"
                >
                  <validation-provider
                      #default="{ errors }"
                      rules="required"
                  >
                    <b-form-input
                        id="data-edit-seourl"
                        ref="myInput"
                        v-model="allCharactersToDash"
                        v-on:focus="$event.target.select()"
                        :state="errors.length > 0 ? false:null"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="2">
                <b-button
                    class="mt-2 ml-2"
                    variant="warning"
                    @click="copyText"
                >
                  Copy
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-card class="mt-1 w-100">
            <h2>Result</h2>
            <div class="col-md-12 mt-3">
              <!--- serp Desktop --->
              <div class="serp-preview d-lg-flex d-none flex-column align-items-start">
                <div class="serp-title">
                  <h4 style="color: rgb(26, 13, 171)!important">
                    {{ data.seoTitle }}
                  </h4>
                </div>
                <div class="serp-url">
                  <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                    <span>{{ baseUrl }}<span class="serp-arrow"></span></span>
                    <span style="overflow-wrap: anywhere">
                      /{{ data.seourl.replaceAll(' ', '-') }}
                    </span>

                  </h5>
                </div>
                <div class="serp-description">
                  <h6 style="overflow-wrap: anywhere">
                    {{ data.seoDescription }}
                  </h6>
                </div>
              </div>
              <!--- serp Mobile --->
              <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-start">
                <div class="serp-title">
                  <h6>
                    {{ data.seoTitle }}
                  </h6>
                </div>
                <div class="serp-url d-flex align-items-center flex-wrap">

                  <small>{{ data.seourl.replaceAll(' ', '-') }}/</small>
                  <small>
                    {{ baseUrl }}
                    <small class="serp-arrow"></small>
                  </small>
                </div>
                <div class="serp-description">
                  <small>
                    {{ data.seoDescription }}
                  </small>
                </div>
              </div>
            </div>
          </b-card>
        </b-row>
      </b-form>
    </b-card>

    <b-card>
      <b-row>
        <b-col cols="12" v-if="data" md="12">
          <h2>Page Content</h2>
          <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="data.content"></ckEditorMain>
        </b-col>
        <b-col class="my-3" cols="6">
          <b-button type="button" class="btn w-25" variant="primary" @click="createData">Submit</b-button>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import AppCollapse from "@core/components/app-collapse/AppCollapse";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem";
import {
  BAvatar, BBadge,
  BButton,
  BCard,
  BCol, BDropdown, BDropdownItem,
  BFormCheckbox,
  BFormFile,
  BFormGroup,
  BCarousel,
  BCarouselSlide,
  BFormInput, BFormSelect, BInputGroup, BLink,
  BMedia, BModal, BOverlay, BPagination,
  BRow,
  BTable, BForm, BFormTextarea
} from "bootstrap-vue";
import vSelect from "vue-select";
import Helper from "@/libs/Helper";
import {getUserData} from "@/auth/utils";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import ckEditorMain from "@/views/components/ckEditorMain";
import {StaticPageGetByPageIdRequest, StaticPageUpdateRequest} from "@/libs/Api/StaticPage";
import {PageCreateRequest} from "@/libs/Api/Page";
import {ValidationProvider} from "vee-validate";
export default {
  components: {
    BFormTextarea, BForm, ValidationProvider,
    ckEditorMain,
    AppCollapse,
    AppCollapseItem,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormFile,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BCarousel,
    BCarouselSlide,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BOverlay,
    BModal,
    BFormSelect,
    vSelect,
  },
  name: "DynamicPageCreate",
  title: "create dynamic page",
  data() {
    return {
      showOverlay: false,
      logoPic: '',
      selectedBanner: null,
      baseUrl: 'https://1minex.com/dynamics',
      shopInfo: [],
      imgUrl: '',
      rawHTML: '',
      banners:[],
      data:{
        pageId: 0,
        title: "",
        content: "",
        link: "",
        seourl: "",
        seoTitle: "",
        seoDescription: "",
        pageShapeType: 0,
        isStatic: false,
        summary: ""
      },
    }
  },
  computed:{
    allCharactersToDash: {
      get: function () {
        return this.data.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.data.seourl = newValue
          // Remove all characters that are NOT number
          this.data.seourl = this.data.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.data.seourl = this.data.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.data.seourl === "") {
          this.data.seourl = null;
        }
      },
    },
  },
  async created() {
    // await this.getData()
  },
  methods: {
    setEditorContent(content){
      this.data.content = content
    },
    async createData() {
      let _this = this;
      _this.showOverlay = true;

      let pageCreateRequest = new PageCreateRequest(_this);
      pageCreateRequest.setParams(_this.data);
      await pageCreateRequest.fetch(function (content) {
        _this.showOverlay = false;
        if (content.isSuccess){
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Done`,
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          _this.$router.push('/apps/dynamic-pages/list');
        }else {
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              text:content.errorMessage,
              title: `error`,
              variant: 'danger',
            },
          })
        }
      }, function (error) {
        console.log(error);
        _this.showOverlay = false;
      })
    },
    customButtonClick() {
      this.shopInfo.description += this.rawHTML
      this.rawHTML = ''
    },
    async copyText() {
      await navigator.clipboard.writeText(this.data.seourl)
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `Copied`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
  }
}
</script>

<style scoped>

</style>
